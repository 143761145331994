<template>
  <div class="search">
    <section>
      <div class="searchbar">
        <input type="text" placeholder="Buscar..." ref="search">
      </div>
      <div class="init-search" v-if="!(movies.data && movies.data.length > 0) && !(news.data && news.data.length > 0)">
        <div class="icon"></div>
        <h1>Búsqueda</h1>
        <h2>Escriba algo...</h2>
      </div>
    </section>
    <section class="peliculas" v-if="movies.data && movies.data.length > 0">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title">Peliculas</h2>
        </div>
        <div class="section-content">   
          <div class="col l2 m3 s4" v-for="movie in movies.data" :key="movie.id">
              <MovieItem :movie="movie" :showPlay="false" :showDateTag="true" :showTitle="true" />
          </div>
          <!--Spinner v-if="isLoadingMore"/>
          <div class="bottom-container" v-if="loadMoreStatus && !isLoadingMore"><a @click="loadMore()" class="load-more">Cargar Más</a></div-->
        </div>
      </div>  
    </section>
    <section class="noticias" v-if="news.data && news.data.length > 0">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title">Noticias</h2>
        </div>
        <div class="section-content">
          <div class="col s6 m4 l3" v-for="(newsItem, index) in news.data" :key=index>
            <NewsItem :item="newsItem" />
          </div>
          <!--Spinner v-if="isLoadingMore"/-->
        </div>
      </div>  
    </section>
  </div>
</template>

<script>
import NewsItem from '@/components/NewsItem.vue'
import MovieItem from '@/components/MovieItem.vue'

export default {
  name: 'Buscador',
  components: {
    NewsItem,
    MovieItem
  },
  data(){
    return{
      news: [],
      movies: []
    }
  },
  mounted(){
    const category = this.$route.params.category

    this.setHeader('page', {
        titulo: 'Buscar'
    })

    let app = this
    this.$refs.search.addEventListener('keyup', function(event){
      app.searchItems(this.value)
    })
  },
  methods:{
    searchItems: async function(query){
      this.news = await this.getItems('noticias', 4, 'q='+query)
      this.movies = await this.getItems('peliculas', 4, 'q='+query)
    }
  }
}
</script>

<style scoped>
.init-search{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.search{
  margin-top: 64px;
  padding-bottom: 32px;
}

.searchbar {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.searchbar input{
  width: 100%;
  padding: 16px 32px;
  font-size: 22px;
}

.init-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.init-search h1{
  color: black;
  text-align: center;
}

.icon {
  background: #aaaa;
  mask: url("/assets/img/search-magnifier.svg");
  mask-size: 80px;
  width: 80px;
  height: 80px;
  mask-repeat: no-repeat;
  mask-position: center;
}

.init-search h2, .icon{
  margin-bottom: 24px;
}

@media(max-width: 1024px){
  .searchbar {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
  }
}

@media(max-width: 768px){
  .searchbar {
    position: fixed;
    top: 54px;
    left: 0;
    width: 100%;
  }

  .searchbar input{
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
  }

  .search{
    margin-top: 44px;
    padding-bottom: 8px;
  }
}

</style>
